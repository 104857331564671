*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
  Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  @include baseFontSize;
  font-weight: 400;
  line-height: 1.35;
  color: $text;
  text-align: left;
  background-color: $background;
}

.game {

  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 3fr;

  user-select: none;

  overflow: hidden;


}

.game__header {

  padding: .25rem;
  @media (min-width: $md) {
    padding: .5rem;
  }
}

.game__field {
  position: relative;

}

.assistant {
  display: grid;
  grid-template-columns: auto 1fr;

}

.assistant__emoji {

  font-size: 2rem;
  @media (min-width: $md) {
    font-size: 3rem;
  }

}

.assistant__heading {
  text-align: center;
  @include headingFontSize;
  padding: 0 .5rem;

  @media (min-width: $md) {
    padding: 0 1rem;
  }
}

.level {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;


}


// level one
.level-one {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.level-two {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  align-items: center;
  justify-content: center;

  & > div {
    height: 100%;
  }
}



.level-three {
  display: grid;
  height: 100%;

  align-items: center;
  justify-content: center;

  & > div {
    height: 100%;
  }

  &--sub-level-0 {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  &--sub-level-1 {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);

    & .button {
      @media (min-width: $sm) {
        max-width: 2.5rem;
        max-height: 2.5rem;
      }
      @media (min-width: $md) {
        max-width: 3rem;
        max-height: 3rem;
      }
      @media (min-width: $lg) {
        max-width: 4rem;
        max-height: 4rem;
      }
      @media (min-width: $xl) {
        max-width: 5rem;
        max-height: 5rem;
      }
      @media (min-width: $fullHD) {
        max-width: 6rem;
        max-height: 6rem;
      }
    }
  }
  &--sub-level-2 {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);

    & .button {
      @media (min-width: $sm) {
        max-width: 2.3rem;
        max-height: 2.3rem;
      }
      @media (min-width: $md) {
        max-width: 2.5rem;
        max-height: 2.5rem;
      }
      @media (min-width: $lg) {
        max-width: 3rem;
        max-height: 3rem;
      }
      @media (min-width: $xl) {
        max-width: 4rem;
        max-height: 4rem;
      }
      @media (min-width: $fullHD) {
        max-width: 5rem;
        max-height: 5rem;
      }
    }
  }
  &--sub-level-3 {
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(7, 1fr);

    & .button {
      @media (min-width: $sm) {
        max-width: 2.2rem;
        max-height: 2.2rem;
      }
      @media (min-width: $md) {
        max-width: 2.4rem;
        max-height: 2.4rem;
      }
      @media (min-width: $lg) {
        max-width: 2.7rem;
        max-height: 2.7rem;
      }
      @media (min-width: $xl) {
        max-width: 3.5rem;
        max-height: 3.5rem;
      }
      @media (min-width: $fullHD) {
        max-width: 4rem;
        max-height: 4rem;
      }
    }
  }
  &--sub-level-4 {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);

    & .button {
      @media (min-width: $sm) {
        max-width: 2.1rem;
        max-height: 2.1rem;
      }
      @media (min-width: $md) {
        max-width: 2.4rem;
        max-height: 2.4rem;
      }
      @media (min-width: $lg) {
        max-width: 2.7rem;
        max-height: 2.7rem;
      }
      @media (min-width: $xl) {
        max-width: 3rem;
        max-height: 3rem;
      }
      @media (min-width: $fullHD) {
        max-width: 3.5rem;
        max-height: 3.5rem;
      }
    }

  }
  &--sub-level-5 {
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(9, 1fr);

    & .button {
      @media (min-width: $sm) {
        max-width: 2rem;
        max-height: 2rem;
      }
      @media (min-width: $md) {
        max-width: 2.1rem;
        max-height: 2.1rem;
      }
      @media (min-width: $lg) {
        max-width: 2.5rem;
        max-height: 2.5rem;
      }
      @media (min-width: $xl) {
        max-width: 2.8rem;
        max-height: 2.8rem;
      }
      @media (min-width: $fullHD) {
        max-width: 3.2rem;
        max-height: 3.2rem;
      }
    }
  }
}


.level-four {
  display: grid;
  height: 100%;

  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(7, 1fr);

  & > div {
    height: 100%;

  }

}

.level-five {
  height: 100%;
}

.level-zero {
  display: grid;
  height: 100%;

  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);


}




.button__container {

}

// BaseButton
.button {
  display: block;
  border: none;
  margin: 0.2rem auto;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: 3rem;
  max-height: 3rem;
  border-radius: 50%;
  overflow: hidden;
  border-bottom: 6px solid darken(red, 30);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  transition: 0.2s;
  background: red;
  color: transparent;
  font: inherit;
  font-size: 4px;
  line-height: normal;
  &:hover {
    cursor: pointer;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.3);
  }
  &:active {
    padding-top: 0.4rem;
    border-bottom: 2px solid darken(red, 30);
  }
  @media (min-width: $sm) {
    max-width: 3rem;
    max-height: 3rem;
  }
  @media (min-width: $md) {
    max-width: 4rem;
    max-height: 4rem;
  }
  @media (min-width: $lg) {
    max-width: 5rem;
    max-height: 5rem;
  }
  @media (min-width: $xl) {
    max-width: 6rem;
    max-height: 6rem;
  }
  @media (min-width: $fullHD) {
    max-width: 7rem;
    max-height: 7rem;
  }
}
.button--base {
  width: 6rem;
  height: 6rem;
}
.button--off {
  background: rgba(255, 255, 255, 0) !important;
  border-bottom: 4px solid rgba(255, 255, 255, 0);
  box-shadow: none;
  outline: none;
  transition: all 1s ease-out;
  &:hover {
    cursor: default;
  }
}



.end-training {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}


.crash-screen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  text-align: center;
}

.crash-screen__image {
  margin: 0 auto;
  user-select: none;
  pointer-events: none;
}


.game-result-score {

}

.game-result-score__heading {

  text-align: center;
  @include bigFontSize;
}

.game-result-score__button {
  display: block;

  padding: 1rem 2rem;
  margin: 2rem auto 0 auto;
  font-size: 1.8rem;
  cursor: pointer;

  background: green;
  color: antiquewhite;
  border-radius: 1rem;
  border: 1px solid antiquewhite;

  text-decoration: none;

}

.statistic {
  margin: 1rem;
  text-align: center;
  @include bigFontSize;
}


.statistic__button {
  display: block;

  padding: 1rem 2rem;
  margin: 2rem auto 0 auto;

  cursor: pointer;

  background: green;
  color: antiquewhite;
  border-radius: 1rem;
  border: 1px solid antiquewhite;

  text-decoration: none;
  @include bigFontSize;
}
