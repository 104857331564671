


.big-button-block {
  display: none;
  @media (min-width: 300px) {
    display: block;
    margin: 0 auto;
    width: 300px;
    position: relative;
    height: 100%;

  }


}
.big-button-block__stand {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  background-color: #ccc;
  border-top: 1px solid wheat;
}
.big-button-block__stand--top {
  display: block;
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  border-bottom: 80px solid #ccc;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  height: 0;
}
.big-button-block__btn-stand {
  display: block;
  position: absolute;
  bottom: 100px;
  left: 75px;
  width: 150px;
  height: 80px;
  background: rgb(199, 3, 3);
  -moz-border-radius: 100px / 50px;
  -webkit-border-radius: 100px / 50px;
  border-radius: 100px / 50px;
}
.big-button-block__btn-border {
  display: block;
  position: absolute;
  bottom: 130px;
  left: 75px;
  width: 150px;
  height: 80px;
  border-bottom: 1px solid rgb(199, 3, 3);
  -moz-border-radius: 100px / 50px;
  -webkit-border-radius: 100px / 50px;
  border-radius: 100px / 50px;
  z-index: 9;
  pointer-events: none;
}
.big-button-block__btn-border--press {
  bottom: 120px;
}
.big-button-block__red-button {
  display: block;

  font-size: 6px;
  color: red;
  background: red;
  outline: none;
  border: none;
  position: absolute;
  bottom: 110px;
  margin: 0;
  left: 75px;
  width: 150px;
  height: 90px;
  border-radius: 100px / 50px;
  &:hover {
    cursor: pointer;
    height: 88px;
  }
  &:focus-visible {
    height: 89px;
  }
  &:active {
    height: 80px;
  }
}