@mixin baseFontSize {
  font-size: 0.8rem;
  @media (min-width: $sm) {
    font-size: 1rem;
  }
  @media (min-width: $md) {
    font-size: 1.2rem;
  }
  @media (min-width: $lg) {
    font-size: 1.3rem;
  }
  @media (min-width: $xl) {
    font-size: 1.4rem;
  }
}

@mixin headingFontSize {
  font-size: 0.8rem;
  @media (min-width: $sm) {
    font-size: 1.2rem;
  }
  @media (min-width: $md) {
    font-size: 1.3rem;
  }
  @media (min-width: $lg) {
    font-size: 1.4rem;
  }
  @media (min-width: $xl) {
    font-size: 2rem;
  }
}

@mixin bigFontSize {
  font-size: 0.8rem;
  @media (min-width: $sm) {
    font-size: 1.2rem;
  }
  @media (min-width: $md) {
    font-size: 1.3rem;
  }
  @media (min-width: $lg) {
    font-size: 1.4rem;
  }
  @media (min-width: $xl) {
    font-size: 2rem;
  }
}